import "./Header.scss";
import logo from "../../assets/logos/lindsay-adams-media-and-design-brant-ontario-favicon.svg";


export default function Header() {
  return (
    <nav className="header">
      <img className="header__logo" src={logo} alt="L.A. Media and Design icon" />
      <div className="header__contact">
        <span className="header__phone">226.920.9764</span>
      </div>
    </nav>
  );
}