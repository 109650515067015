import "./CustomButton.scss";

const CustomButton = ({
  label,
  onClick,
  href,
  variant = "default",
  disabled = false,
}) => {
  if (href) {
    return (
      <a
        className={`custom-button ${variant} ${disabled ? "disabled" : ""}`}
        href={href}
        onClick={disabled ? (e) => e.preventDefault() : undefined} // Prevents action if disabled
        target={href.startsWith("mailto:") ? "_self" : "_blank"} // Ensures mailto links open in the same tab
        rel="noopener noreferrer"
      >
        {label}
      </a>
    );
  }

  return (
    <button
      className={`custom-button ${variant} ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default CustomButton;
