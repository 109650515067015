import React from "react";
import "./TextGrid.scss";

const TextGrid = ({ leftText, rightText }) => {
  return (
    <div className="two-column-text">
      <div className="column left">
        <h2>{leftText}</h2>
      </div>
      <div className="column right">
        <h5>{rightText}</h5>
      </div>
    </div>
  );
};

export default TextGrid;
