import "./App.scss";
import { Routes, Route } from "react-router-dom";

import usePageTracking from "./usePageTracking"; // Import the custom hook

import Header from "./components/Header/Header";
import HomePage from "./pages/HomePage/HomePage";
// import About from "./pages/About/About";
// import Services from "./pages/Services/Services";
// import Contact from "./pages/Contact/Contact";
import Footer from "./components/Footer/Footer";

function App() {
  // Call the custom hook to track page views
  usePageTracking();

  return (
    <div className="app">
      <Header className="app__header" />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
