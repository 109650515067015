import React from "react";
import "./HeroImage.scss"; // Create this stylesheet if needed

const HeroImage = ({ src, alt }) => {
  return (
    <div className="hero-image">
      <img src={src} alt={alt} />
    </div>
  );
};

export default HeroImage;
