import React from "react";
import "./ScrollingServices.scss"; // Import your stylesheet

const ScrollingServices = () => {
  const services = [
    "Branding and Logo Design",
    "Print Design",
    "Web Design",
    "Social Media Graphics",
    "Packaging Design",
    "Infographic Design",
    "Advertising Design",
    "UI/UX Design",
    "Illustration Services",
    "Promotional Materials",
  ];

  return (
    <div className="scrolling-services">
      <div className="scrolling-content">
        {services.map((service, index) => (
          <div className="service-item" key={index}>
            {service}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollingServices;
