import "./Footer.scss";
import logo from "../../assets/logos/lindsay-adams-media-and-design-brant-ontario-logo.svg";


export default function Footer() {
  return (
    <div className="footer">
<img className="footer__logo" src={logo} alt="L.A. Media and Design Logo" />
<div className="footer__text">
<h5><a href="mailto:lindsayadamsdesign@gmail.com">lindsayadamsdesign@gmail.com</a></h5>
        <h5>226.920.9764</h5>
        <h5>Brant, Ontario</h5>
      </div>
    </div>
  );
}