import "./HomePage.scss";
import React from "react";
import heroImage from "../../assets/images/graphic-design-billboard-garden-nursery-toronto-ontario.jpg";
import PortfolioGrid from "../../components/PortfolioGrid/PortfolioGrid";
import HeroImage from "../../components/HeroImage/HeroImage";
import TextGrid from "../../components/TextGrid/TextGrid";
import CustomButton from "../../components/CustomButton/CustomButton";
import ScrollingServices from "../../components/ScrollingServices/ScrollingServices";


export default function HomePage() {
  return (
    <div className="home-page">
       <h1>Professional Graphic Design Services: Elevating Print and Digital Creativity</h1>
      {/* Hero Image Component */}
      <HeroImage src={heroImage} alt="Beech Nurseries billboard" />
      <ScrollingServices />
      <PortfolioGrid />
      <TextGrid
  leftText="Meet Your Next Cross-Media Design Partner!"
  rightText={
    <>
      Welcome to the realm of transformative design where brands thrive and experiences flourish. I'm Lindsay Adams, your dedicated cross-media design collaborator, ready to elevate your brand presence across every platform and touchpoint.
      <div style={{ height: ".5rem" }}></div> {/* Spacer */}
      With a passion for crafting immersive experiences and a knack for blending creativity with strategy, I specialize in bringing your brand's vision to life. Whether it's through captivating visuals, interactive interfaces, or engaging multimedia content, I'm here to empower your brand and enrich the experiences of your audience.
    </>
  }
/>
        <CustomButton
        label="Contact Lindsay"
        href="mailto:lindsay@example.com"/>
        
        <TextGrid
  leftText="Let's Create Something Extraordinary"
  rightText={
    <>
      Whether you're launching a new brand, refreshing your visual identity, or seeking to elevate your online presence, I'm here to help. Let's embark on a journey of creativity and collaboration, where together, we'll craft experiences that leave a lasting impression.
      <div style={{ height: ".5rem" }}></div> {/* Spacer */}
      Ready to empower your brand and enrich the experiences of your audience? Let's connect and turn your vision into reality.
    </>
  }
/>
        <CustomButton
        label="Contact Your Cross-media Designer Today!"
        href="mailto:lindsay@example.com"/>

    </div>
  );
}
